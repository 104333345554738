import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Send, Inbox, Person } from "@mui/icons-material";
import { getText } from "../utils/texts";

const BottomNavigationBar = ({ currentScreen, setCurrentScreen, language }) => (
  <BottomNavigation
    value={currentScreen}
    onChange={(event, newValue) => setCurrentScreen(newValue)}
    showLabels
    sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
  >
    
    <BottomNavigationAction label={getText("sendIcon", language)} value="send" icon={<Send />} />
    <BottomNavigationAction label={getText("inboxIcon", language)} value="inbox" icon={<Inbox />} />
  </BottomNavigation>
);

export default BottomNavigationBar;
