import React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { getText } from "../utils/texts";

const ModalView = ({
  open,
  onClose,
  selectedMessage,
  replyText,
  setReplyText,
  handleReplySubmit,
  language = "en",
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      p={3}
      bgcolor="background.paper"
      mx="auto"
      mt={5}
      maxWidth="600px"
      borderRadius={2}
      boxShadow={3}
    >
      <Typography variant="h6" color="text.primary" gutterBottom>
          {getText("question", language)}
      </Typography>
      <Box
        p={2}
        mb={2}
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#303841" : "#f5f5f5",
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        <Typography variant="body1" color="text.secondary">
          {selectedMessage?.fullText}
        </Typography>
      </Box>
      {!selectedMessage?.reply_text ? (
        <>
          <TextField
            label={getText("writeReply", language)}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleReplySubmit(selectedMessage.id, replyText)}
            sx={{ mt: 2 }}
          >
            {getText("sendReply", language)}
          </Button>
        </>
      ) : (
        <Box
          mt={2}
          sx={{
            p: 2,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#405d7c" : "#e3f2fd",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Typography
            variant="subtitle2"
            color="text.primary"
            sx={{
              mb: 1,
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: 1,
            }}
          >
            {getText("messageReply", language)}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {selectedMessage?.reply_text}
          </Typography>
        </Box>
      )}
      <Button onClick={onClose} sx={{ mt: 2 }}>
        {getText("close", language)}
      </Button>
    </Box>
  </Modal>
);

export default ModalView;
