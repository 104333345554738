import React from "react";
import { useState } from "react";
import { Box, Typography, Alert, Button } from "@mui/material";
import { getText } from "../utils/texts";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ReferralPage({ referralLink, onClose, language, handleAcceptReferral }) {

    const [tooltipText, setTooltipText] = useState("Copy to clipboard");

    const handleCopy = () => {
      navigator.clipboard.writeText(referralLink).then(() => {
        setTooltipText("Copied!");
        setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
      });
    };

    return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" mb={2} align="center">
        {getText("anonymousQuestionSent", language)}
      </Typography>
      <Typography mb={2} align="center">
        {getText("shareLinkPrompt", language)}
      </Typography>
      {referralLink && (
        <Alert
        icon={false}
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleCopy}
      >
        <span>{referralLink}</span>
          <Tooltip title={tooltipText} arrow>
            <IconButton
              aria-label="copy referral link"
              onClick={handleCopy}
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAcceptReferral}
        sx={{ width: "100%", mt: 2 }}
      >
        {getText("share", language)}
      </Button>
    </Box>
  );
}

export default ReferralPage;
