import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Skeleton,
  Alert,
} from "@mui/material";
import { getText } from "../utils/texts";

const SendPage = ({ avatarUrl, username, question, setQuestion, setShowConfirmDialog, language }) => (
    <Box p={2}>
      <Box display="flex" alignItems="center" mb={2}>
        {avatarUrl ? (
          <Avatar src={avatarUrl} alt={username} sx={{ width: 56, height: 56, mr: 2 }} />
        ) : (
          <Skeleton variant="circular" width={56} height={56} sx={{ mr: 2 }} />
        )}
        {username ? (
          <Typography variant="h6">{username}</Typography>
        ) : (
          <Skeleton variant="text" width={150} />
        )}
      </Box>
      <Alert severity="info" sx={{ mb: 2 }}>
        {getText("sendingMessageTo", language)} <b>{username}</b>. {getText("messageGuidelines", language)}
      </Alert>
      <TextField
        label={getText("yourQuestion", language)}
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        inputProps={{ maxLength: 250 }}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowConfirmDialog(true)}
        disabled={!question.trim()}
      >
        {getText("send", language)}
      </Button>
    </Box>
  );

export default SendPage;
