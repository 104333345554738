import React from "react";
import { Box, Typography, Button } from "@mui/material";

function RequestWriteAccess() {
  const handleRequestAccess = () => {
    if (window.Telegram?.WebApp?.requestWriteAccess) {
      window.Telegram.WebApp.requestWriteAccess();
    } else {
      console.error("Метод requestWriteAccess недоступен.");
    }
  };

  return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" mb={2} align="center">
        Вам необходимо дать разрешение на отправку сообщений боту.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRequestAccess}
        sx={{ mt: 2 }}
      >
        Предоставить разрешение
      </Button>
    </Box>
  );
}

export default RequestWriteAccess;