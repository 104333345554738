import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  BottomNavigation,
  BottomNavigationAction,
  Skeleton,
} from "@mui/material";
import { getText } from "../utils/texts";

const InboxPage = ({
    currentMessages,
    currentReplies,
    inboxTab,
    setScreenByEvent,
    handleMessageClick,
    handleReplyClick,
    handlePaginationChange,
    language,
    itemsPerPage,
    messages,
    replies,
    currentPage,
    repliesPage,
  }) => (
    <Box>
      <BottomNavigation
        value={inboxTab}
        onChange={(event, newValue) => setScreenByEvent(newValue)}
        showLabels
        sx={{ marginBottom: 2 }}
      >
        <BottomNavigationAction label={getText("questions", language)} value="questions" />
        <BottomNavigationAction label={getText("answers", language)} value="answers" />
      </BottomNavigation>
      {inboxTab === "questions" ? (
        <List>
          {currentMessages.length > 0 ? (
            currentMessages.map((msg) => (
              <ListItem button key={msg.id} divider onClick={() => handleMessageClick(msg)}>
                <ListItemText primary={msg.shortText} />
                <Button
                  variant="text"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMessageClick(msg);
                  }}
                  sx={{ textTransform: "none", ml: 2, fontSize: "0.8rem" }}
                >
                  {getText("reply", language)}
                </Button>
              </ListItem>
            ))
          ) : (
            Array.from({ length: itemsPerPage }).map((_, index) => (
              <ListItem key={`placeholder-question-${index}`} divider>
                <ListItemText primary={getText("noMessages", language)} secondary={getText("waitingForQuestions", language)} />
              </ListItem>
            ))
          )}
        </List>
      ) : (
        <List>
          {currentReplies.length > 0 ? (
            currentReplies.map((reply) => (
              <ListItem button key={reply.id} divider>
                <ListItemText primary={`${getText("questionDiv", language)} ${reply.message_text}`} secondary={`${getText("replyDiv", language)} ${reply.fullText}`} onClick={()=>handleReplyClick(reply)}/>
              </ListItem>
            ))
          ) : (
            Array.from({ length: itemsPerPage }).map((_, index) => (
              <ListItem key={`placeholder-reply-${index}`} divider>
                <ListItemText primary={getText("noMessages", language)} secondary={getText("waitingForQuestions", language)} />
              </ListItem>
            ))
          )}
        </List>
      )}
      <Pagination
        count={Math.ceil(
          inboxTab === "questions" ? messages.length / itemsPerPage : replies.length / itemsPerPage
        ) || 1}
        page={inboxTab === "questions" ? currentPage : repliesPage}
        onChange={(event, page) => handlePaginationChange(event, page, inboxTab)}
      />
    </Box>
  );

export default InboxPage;
