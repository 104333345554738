import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import axios from "axios";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import SendPage from "./components/SendPage";
import InboxPage from "./components/InboxPage";
import ReferralPage from "./components/ReferralPage";
import ModalView from "./components/ModalView";
import ModalViewReplies from "./components/ModalViewReplies";
import BottomNavigationBar from "./components/BottomNavigation";
import RequestWriteAccess from "./components/AccessPage";
import { getText } from "./utils/texts";

const apiBaseUrl = process.env.REACT_APP_BACKEND_ADDRESS;

export default function App() {
  const [currentScreen, setCurrentScreen] = useState(""); // 'send' or 'inbox'
  const [username, setUsername] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [refId, setRefId] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [telegramTheme, setTelegramTheme] = useState(createTheme());
  const [inboxTab, setInboxTab] = useState("questions");
  const [replyText, setReplyText] = useState("");
  const [replies, setReplies] = useState([]);
  const [language, setLanguage] = useState("en");
  const [showReply, setShowReply] = useState(false);

  useEffect(() => {
    const initTelegram = async () => {
      const WebApp = window.Telegram.WebApp;
      if (WebApp) {

        const themeParams = WebApp.themeParams || {};
        const colorScheme = WebApp.colorScheme || "light";
        const theme = createTheme({
          palette: {
            mode: colorScheme,
            background: {
              default: themeParams.background_color || (colorScheme === "dark" ? "#121212" : "#ffffff"),
              paper: themeParams.secondary_background_color || (colorScheme === "dark" ? "#1f1f1f" : "#fafafa"),
            },
            text: {
              primary: themeParams.text_color || (colorScheme === "dark" ? "#ffffff" : "#000000"),
              secondary: themeParams.hint_color || (colorScheme === "dark" ? "#999999" : "#555555"),
            },
            primary: {
              main: themeParams.button_color || (colorScheme === "dark" ? "#3f51b5" : "#1976d2"),
              contrastText: themeParams.button_text_color || "#ffffff",
            },
            secondary: {
              main: themeParams.link_color || (colorScheme === "dark" ? "#bb86fc" : "#1976d2"),
            },
            components: {
              MuiAlert: {
                styleOverrides: {
                  root: {
                    backgroundColor: colorScheme === "dark" ? "#2e7d32" : "#d9f0d8", // lighter green for both themes
                  },
                },
              },
            },
          },
        });
        setTelegramTheme(theme);

        const userId = WebApp.initDataUnsafe.user?.id || 0;
        const languageCode = WebApp.initDataUnsafe.user?.language_code || "en";
        setLanguage(languageCode);

        const queryParams = new URLSearchParams(window.location.search);
        const encodedParams = queryParams.get("tgWebAppStartParam") || 0;
        const params = encodedParams.split("__");
        console.log(params);

        let refIdParam = params[0] || null;
        let page = params[1] || null;
        let messageId = params[2] || null;

        setRefId(refIdParam);
        const chatIdFromServer = await fetchUserInfo(refIdParam || userId);
        setChatId(userId);

        let currentMessages  = await fetchMessages(userId);
        let currentReplies = await fetchReplies(userId);
  
        if (page === "questions" || page === "answers") {
          setCurrentScreen("inbox");
          setInboxTab(page);

          if (messageId) {
            if (page === "questions") {
              openMessageById(
                currentMessages,
                messageId,
                page
              );
            } else if (page === "answers") {
              openMessageById(
                currentReplies,
                messageId,
                page
            );
            }
          }
        }
        else {
          setCurrentScreen("send");
        }
      } else {
        alert("Telegram Web App is not available");
      }
    };
    initTelegram();
  }, []);


  const setScreenByEvent = (newValue) => {
    if (newValue === "inbox") {
      fetchMessages(chatId);
      setCurrentScreen(newValue);
    }
    else if (newValue === "answers") {
      fetchReplies(chatId);
      setInboxTab("answers");
    }
    else if (newValue === "questions") {
      fetchMessages(chatId);
      setInboxTab("questions");
    }
    else {
      setCurrentScreen(newValue);
    }
    
  }
  
  const fetchUserInfo = async (ref_id) => {

    try {
      const response = await axios.get(`${apiBaseUrl}/user_info?user_id=${ref_id}`);
      setUsername(response.data.full_name);
      setAvatarUrl(response.data.avatar_url);
      return response.data.chat_id;
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }

  };

  const handleAcceptReferral = async () => {
    try {

      console.log(`Referral url is: ${referralLink}`)
      const prepareResponse = await axios.post(`${apiBaseUrl}/prepare_message`, { user_id: chatId, language_code: language });

      console.log(prepareResponse.data)
      const { id: msg_id } = prepareResponse.data.prepared_message_id;
      
      let WebApp = window.Telegram.WebApp;
      
      WebApp.shareMessage(msg_id, (success) => {
        if (success) {
          console.log("Message shared successfully!");
        } else {
          console.error("Message sharing failed.");
        }
      });
    } catch (error) {
      console.error("Error preparing or sharing the message:", error);
    }
  };
  
  const handleDeclineReferral = () => {
    setShowReferralDialog(false);
  };

  const fetchMessages = async (chat_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/messages?chat_id=${chat_id}`);
      setMessages(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      return [];
    }
  };

  const fetchReplies = async (chat_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/replies?chat_id=${chat_id}`);
      setReplies(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch replies:", error);
      return [];
    }
  };

  const openMessageById = (currentMessages, messageId, type) => {

      const targetArray = currentMessages;

      console.log(targetArray, messageId)
      let message = targetArray.find((msg) => msg.id === Number(messageId));

      if (message) {

          setSelectedMessage(message);

          if (type === "questions") {
            setShowModal(true);
          }
          else if (type === "answers") {
            setShowReply(true);
          }

      } else {
          console.error(`Message with ID ${messageId} not found in ${type}.`);
      }
  };

  const confirmSendMessage = async () => {
    setShowConfirmDialog(false);

    try {

      await axios.post(`${apiBaseUrl}/ask`, {
        user_id: refId,
        sender_chat_id: chatId.toString(),
        question: question,
      });

      setQuestion("");
      handleReferral();
      //setSnackbarOpen(true);
      setCurrentScreen('referral');

    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };


  const handleReplySubmit = async (messageId, replyText) => {
    try {
        await axios.post(`${apiBaseUrl}/reply`, {
            message_id: Number(messageId),
            reply_text: replyText,
            chat_id: chatId.toString(),
        });

        await fetchMessages(chatId);
        setReplyText("");
        setShowModal(false);
    } catch (error) {
        console.error("Failed to send reply:", error);
    }
  };


  const handleReferral = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/send_referral`, { user_id: String(chatId) });
      setReferralLink(response.data.referral_url);
    } catch (error) {
      console.error("Failed to generate referral link:", error);
      alert("Failed to generate referral link.", error);
    }
  };

  
  const handlePaginationChange = (event, page, type) => {
    if (type === "questions") {
        setCurrentPage(page);
    } else if (type === "answers") {
        setRepliesPage(page);
    }
  };

  const handleMessageClick = (message) => {
      setSelectedMessage(message);
      setShowModal(true);
  };

  const handleReplyClick = (reply) => {
      setSelectedMessage(reply);
      setShowReply(true);
  }

  const currentMessages = messages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [repliesPage, setRepliesPage] = useState(1);

  // update currentReplies based on pagination
  const currentReplies = replies.slice(
      (repliesPage - 1) * itemsPerPage,
      repliesPage * itemsPerPage
  );

  return (
    <ThemeProvider theme={telegramTheme}>
      <CssBaseline />
      <Box>
        {/* Access Page in userId == 0, else - other code*/}
        {chatId === 0 ? (
          <RequestWriteAccess />
        ) : (
        <>
        {currentScreen === "send" && (
          <SendPage
            username={username}
            avatarUrl={avatarUrl}
            question={question}
            setQuestion={setQuestion}
            snackbarOpen={snackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
            setShowConfirmDialog={setShowConfirmDialog}
            language={language}
          />
        )}
        {currentScreen === "inbox" && (
          <InboxPage
            currentMessages={currentMessages}
            currentReplies={currentReplies}
            inboxTab={inboxTab}
            setScreenByEvent={setScreenByEvent}
            handleMessageClick={handleMessageClick}
            handleReplyClick={handleReplyClick}
            handlePaginationChange={handlePaginationChange}
            language={language}
            itemsPerPage={itemsPerPage}
            messages={messages}
            replies={replies}
            currentPage={currentPage}
            repliesPage={repliesPage}
          />
        )}

        {currentScreen === "referral" && (
          <ReferralPage
            referralLink={referralLink}
            onClose={() => setCurrentScreen("send")}
            language={language}
            handleAcceptReferral={handleAcceptReferral}
          />
        )}

        {/* Modal for viewing and replying to messages */}
        <ModalView
          open={showModal}
          onClose={() => setShowModal(false)}
          selectedMessage={selectedMessage}
          replyText={replyText}
          setReplyText={setReplyText}
          handleReplySubmit={handleReplySubmit}
          language={language}
        />

        <ModalViewReplies
          open={showReply}
          onClose={() => setShowReply(false)}
          selectedReply={selectedMessage}
          language={language}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%" }}>
            {getText("messageSent", language)} {username}!
          </MuiAlert>
        </Snackbar>

        {/* Confirmation Dialog */}
        <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
          <DialogTitle>{getText("sendAnonymousMessage", language)}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {getText("confirmSend", language)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmDialog(false)} color="secondary">
              {getText("cancel", language)}
            </Button>
            <Button
              onClick={confirmSendMessage}
              color="primary"
            >
              {getText("send", language)}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Bottom Navigation */}
        <BottomNavigationBar
          currentScreen={currentScreen}
          setCurrentScreen={setScreenByEvent}
          language={language}
        />
        </>
        )}
      </Box>
    </ThemeProvider>
  );
}
